import React, { Component } from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import Modal from "react-modal"

import Layout from "../components/landing-page-layout"
import LandingPageSEO from "../components/landing-page-seo"
import LandingPageVideo from "../components/landing-page-video"
import LandingPageSlider from "../components/landing-page-slider"

import VideoThumb from "../images/video-thumb.png"
import Propane from "../svgs/landing-page/propane.svg"
import Yokogawa from "../svgs/landing-page/yokogawa.svg"
import Flintco from "../svgs/landing-page/flintco.svg"
import Homesite from "../svgs/landing-page/homesite.svg"
import Ko from "../svgs/landing-page/ko.svg"
import SilentQuadrant from "../svgs/landing-page/silent-quadrant.svg"
import Native from "../svgs/landing-page/native.svg"
import Beef from "../svgs/landing-page/beef-loving-texans.svg"
import ScienceData from "../svgs/landing-page/science-data.svg"
import Fun from "../svgs/landing-page/fun.svg"
import DataDrivenStrategy from "../svgs/landing-page/data-driven-strategy.svg"
import HumanConnections from "../svgs/landing-page/human-connections.svg"
import DemandForYourBrand from "../svgs/landing-page/demand-for-your-brand.svg"

import { FaRegPlayCircle } from "react-icons/fa"
import headerBackground from "../images/tech-worker-bg.jpeg"

class LandingPage extends Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleOpenModal() {
    this.setState({ showModal: true })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <Layout>
        <LandingPageSEO />
        <div style={{ padding: "0 20px" }}>
          <TitleSection>
            <div className="bg-overlay">
              <h1>Your Digital Marketing Experts</h1>
            </div>
          </TitleSection>
          <CopySection>
            <h2>Ready to take your brand to the next level?</h2>
            <p>
              Backed by data-driven insights, White Lion helps you build brand
              awareness and drive value for your company through user-centered
              experiences that engage your target audience. From content
              creation to conversion optimization, we combine data-intelligence
              with 25+ years of industry expertise to guide strategy and deliver
              measurable results.
            </p>
            <button
              className="red-button"
              onClick={() => scrollTo("#i2i_contact")}
            >
              Book Your Strategy Session
            </button>
          </CopySection>
          <CustomModal
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
          >
            <ModalContent>
              <button
                onClick={this.handleCloseModal}
                className={"close"}
                aria-label="Close"
              >
                ×
              </button>
              <LandingPageVideo
                videoSrcURL="https://www.youtube.com/embed/3ndHEDhU60k"
                videoTitle="Intelligence to Inspiration Book Your Free Strategy Session"
              />
            </ModalContent>
          </CustomModal>
          <CopyThreeCol>
            <h2>Insights are Everything</h2>
            <p>Take the Guesswork out of Digital Marketing.</p>
            <div className="three-col">
              <div>
                <div className="icon-block">
                  <DataDrivenStrategy className="data-driven-strategy" />
                </div>
                <h3>Data-driven Strategy</h3>
                <p>
                  We harness research-based insights with our industry expertise
                  to answer critical questions, shape messaging and drive
                  strategy.
                </p>
              </div>
              <div>
                <div className="icon-block">
                  <HumanConnections className="human-connections" />
                </div>
                <h3>Human Connections</h3>
                <p>
                  We craft authentic human connections through digital
                  experiences to help you connect and engage your target
                  audience.
                </p>
              </div>
              <div>
                <div className="icon-block">
                  <DemandForYourBrand className="demand-for-your-brand" />
                </div>
                <h3>Demand for your Brand</h3>
                <p>
                  Backed by data, we connect your brand to the right target,
                  with the right message, at the right time to create real
                  demand.
                </p>
              </div>
            </div>
          </CopyThreeCol>
          <LogoSection>
            <h2>Clients We've Helped</h2>
            <div className="logo-row">
              <Propane className="propane" />
              <Yokogawa className="yokogawa" />
              <Flintco className="flintco" />
              <Homesite className="homesite" />
              <Ko className="ko" />
              <SilentQuadrant className="silent-quadrant" />
              <Native className="native" />
              <Beef className="beef" />
            </div>
          </LogoSection>
          <TestimonialSection>
            <h3>What our clients are saying</h3>
            <LandingPageSlider />
          </TestimonialSection>
          <MainCopy>
            <h2>Our Proven Process is Called Intelligence 2 Inspiration</h2>
            <div className="two-col-first">
              <div>
                <h4>Intelligence</h4>
                <h3>First, the Science...</h3>
                <p>
                  Successful digital marketing begins with data. By overlaying
                  behavioral science and innovative thinking with 25+ years of
                  industrial expertise, we formulate a plan to achieve your
                  marketing goals. This data-driven approach guides us in
                  identifying your target audiences, aligning creative or
                  building a web strategy.
                </p>
              </div>
              <div>
                <ScienceData className="science-data" />
              </div>
            </div>
            <div className="two-col-second">
              <div>
                <Fun />
              </div>
              <div>
                <h4>Inspiration</h4>
                <h3>...Then, the Fun</h3>
                <p>
                  We create captivating experiences that inspire consumers to
                  engage with your brand. From brand strategy to web development
                  to digitial marketing, driving results and helping your
                  business grow isn't just what we do; it's our obsession.
                </p>
              </div>
            </div>
          </MainCopy>
          <ActionSection>
            <h3>
              Get a 30-min Strategy Session With a Digital Marketing Expert
            </h3>
            <button
              className="red-button"
              onClick={() => scrollTo("#i2i_contact")}
            >
              Book Your Strategy Session
            </button>
            <button
              className="image-button"
              onClick={this.handleOpenModal}
              aria-label="Open"
            >
              <img
                src={VideoThumb}
                alt="landing page video thumb"
                height="225"
                width="400"
              />
              <FaRegPlayCircle size={48} />
            </button>
          </ActionSection>
          <ContactSection id="i2i_contact">
            <h2>Book Your Strategy Session</h2>
            <h3>
              Get a 30-min Strategy Session With a Digital Marketing Expert
            </h3>
            <form
              name="i2i Contact Form"
              method="POST"
              netlify-honeypot="bot-field"
              action="/thank-you-i2i/"
              data-netlify="true"
            >
              <input
                type="hidden"
                name="form-name"
                value="i2i Contact Form"
                aria-label="Input"
              />
              <p className="hidden">
                <label>
                  Don’t fill this out if you're human:{" "}
                  <input name="bot-field" aria-label="Input" />
                </label>
              </p>
              <p className="full-width">
                <label htmlFor="first-name">
                  First Name*{" "}
                  <input
                    type="text"
                    name="first-name"
                    aria-label="Input"
                    required
                  />
                </label>
              </p>
              <p className="full-width">
                <label htmlFor="last-name">
                  Last Name*{" "}
                  <input
                    type="text"
                    name="last-name"
                    aria-label="Input"
                    required
                  />
                </label>
              </p>
              <p className="full-width">
                <label htmlFor="email">
                  Email*{" "}
                  <input
                    type="email"
                    name="email"
                    aria-label="Input"
                    required
                  />
                </label>
              </p>
              <p className="full-width">
                <label htmlFor="phone">
                  Phone <input type="text" name="phone" aria-label="Input" />
                </label>
              </p>
              <p className="full-width">
                <label htmlFor="company">
                  Company Name{" "}
                  <input type="text" name="company" aria-label="Input" />
                </label>
              </p>
              <p className={"button"}>
                <button
                  type="submit"
                  name="submit"
                  className={"submit"}
                  aria-label="Send"
                >
                  Schedule My Strategy Session
                </button>
              </p>
            </form>
          </ContactSection>
        </div>
      </Layout>
    )
  }
}

const CopySection = styled.section`
  max-width: 1200px;
  width: 100%;
  margin: 60px auto;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    max-width: 730px;
    display: inline-block;
    font-family: "Nobel", sans-serif;
    font-size: 65px;
    line-height: 1.1;
    font-weight: 700;
    color: #2b2520;
    margin-top: 0;
    margin-bottom: 28px;
  }
  p {
    font-family: "Work Sans";
    font-size: 16px;
    line-height: 2.2;
    font-weight: 400;
    color: #777c7c;
    margin-top: 0;
    margin-bottom: 40px;
    max-width: 871px;
  }
  button.red-button {
    font-family: "Work Sans";
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(219, 65, 64);
    display: inline-block;
    padding: 20px 50px;
    margin: 0px auto 15px;
    border: none;
    outline: 0;
    &:hover {
      cursor: pointer;
    }
  }
  @media (max-width: 1000px) {
    max-width: 700px;
    padding: 0 55px;
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    margin: 20px auto;
    padding: 0px;
    h2 {
      font-size: 28px;
      line-height: 1.3;
    }
    p {
      font-size: 16px;
      line-height: 1.6;
    }
    button.red-button {
      padding: 20px 30px;
    }
  }
`

const TitleSection = styled.header`
  background-image: url(${headerBackground});
  background-size: cover;
  max-width: 1320px;
  min-height: 450px;
  width: 100%;
  padding: 80px 70px;
  margin: 20px auto 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 72px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    color: white;
    width: 100%;
  }
  .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1000px) {
    max-width: 700px;
    padding: 45px;
    min-height: 280px;
    h1 {
      font-size: 54px;
    }
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    padding: 20px;
    margin-bottom: 20px;
    min-height: 134px;
    h1 {
      font-size: 31px;
      width: 80%;
    }
  }
`

const CustomModal = styled(Modal)`
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`

const ModalContent = styled.div`
  background-color: transparent;
  margin: auto;
  padding: 00px;
  border: none;
  width: 50%;
  position: relative;
  @media (max-width: 1200px) {
    width: 75%;
  }
  @media (max-width: 900px) {
    width: 90%;
  }
  button {
    &.close {
      font-family: "Raleway Bold";
      line-height: 28px;
      color: #fff;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      top: -30px;
      right: -20px;
      background: transparent;
      border: none;
      outline: 0;
      padding: 0;
      z-index: 10;
      &:hover {
        cursor: pointer;
        color: #000;
      }
    }
  }
`

const LogoSection = styled.section`
  max-width: 1190px;
  width: 100%;
  margin: 60px auto;
  h2 {
    text-align: center;
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 42px;
    margin-top: 0;
    margin-bottom: 60px;
  }
  .logo-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      max-width: 120px;
      width: 100%;
      margin: 0 20px;
      &.ko {
        max-width: 114px;
      }
      &.oncor {
        max-width: 83px;
      }
      &.beef {
        max-width: 54px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 1000px) {
    max-width: 700px;
    .logo-row {
      flex-wrap: wrap;
      padding: 0 20px;
      svg {
        margin-top: 10px;
        margin-bottom: 10px;
        &:first-child {
          margin-left: 20px;
        }
        &:last-child {
          margin-right: 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    margin: 30px auto;
    h2 {
      font-size: 28px;
      margin-bottom: 30px;
    }
    .logo-row {
      padding: 0;
      justify-content: center;
      svg {
        max-width: 60px !important;
        max-height: 40px;
        margin: 10px !important;
      }
    }
  }
`

const TestimonialSection = styled.section`
  max-width: 1190px;
  width: 100%;
  padding: 60px;
  margin: 60px auto;
  text-align: center;
  background-color: rgb(234, 234, 234);
  h3 {
    text-align: center;
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  @media (max-width: 1000px) {
    max-width: 700px;
  }
  @media (max-width: 767px) {
    max-width: 500px;
    margin: 30px auto;
    padding: 20px;
    h3 {
      font-size: 24px;
    }
  }
`

const MainCopy = styled.section`
  max-width: 1190px;
  width: 100%;
  margin: 60px auto;
  h2 {
    display: block;
    text-align: center;
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 60px;
    margin: 0 auto 60px;
    max-width: 800px;
  }
  h3 {
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  h4 {
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 10px;
    color: #db4140;
    text-transform: uppercase;
  }
  p {
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 2.2;
    color: rgb(119, 124, 124);
    margin-bottom: 0px;
  }
  .two-col-first {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 60px auto;
    > div {
      &:first-child {
        max-width: 520px;
        width: 100%;
        padding-right: 60px;
      }
      &:last-child {
        max-width: 670px;
        width: 100%;
        .science-data {
          max-width: 670px;
          width: 100%;
        }
      }
    }
  }
  .two-col-second {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 60px auto;
    > div {
      &:first-child {
        max-width: 607px;
        width: 100%;
        .fun {
          max-width: 607px;
          width: 100%;
        }
      }
      &:last-child {
        max-width: 583px;
        width: 100%;
        padding-left: 60px;
      }
    }
  }
  .cta-row {
    width: 100%;
    max-width: 920px;
    margin: 60px auto;
    padding: 50px;
    border: 10px solid #db4140;
    text-align: center;
    h3 {
      font-family: Nobel, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 36px;
      line-height: 1.4;
      margin: 0 auto 20px;
      text-align: center;
      max-width: 520px;
    }
    button {
      font-family: "Work Sans";
      font-size: 20px;
      line-height: 1;
      font-weight: 700;
      color: rgb(255, 255, 255);
      text-decoration: none;
      background-color: rgb(219, 65, 64);
      display: inline-block;
      padding: 20px 50px;
      margin: 0px auto;
      border: none;
      outline: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1000px) {
    max-width: 700px;
    .two-col-first {
      flex-wrap: wrap;
      > div {
        &:first-child {
          padding-right: 0;
          margin-bottom: 40px;
        }
        &:last-child {
          max-width: 520px;
        }
      }
    }
    .two-col-second {
      flex-wrap: wrap;
      > div {
        &:first-child {
          order: 2;
          max-width: 520px;
        }
        &:last-child {
          order: 1;
          max-width: 520px;
          padding-left: 0;
          margin-bottom: 40px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    margin: 30px auto;
    h2 {
      font-size: 30px;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 24px;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
      line-height: 1.6;
    }
    .two-col-first {
      margin: 30px auto;
    }
    .two-col-second {
      margin: 30px auto;
    }
    .cta-row {
      margin: 30px auto;
      padding: 20px;
      border: 5px solid rgb(219, 65, 64);
      h3 {
        font-size: 28px;
        line-height: 1.3;
      }
      button {
        padding: 10px;
        font-size: 16px;
      }
    }
  }
`

const CopyThreeCol = styled.section`
  max-width: 1190px;
  width: 100%;
  margin: 60px auto;
  h2 {
    text-align: center;
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 54px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
    color: rgb(119, 124, 124);
    max-width: 840px;
    margin: 0px auto 40px;
    text-align: center;
  }
  .three-col {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    > div {
      width: 33.33%;
      padding: 0 50px;
      text-align: center;
      .icon-block {
        height: 140px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      h3 {
        font-family: din-condensed, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 30px;
        margin-top: 60px;
        margin-bottom: 10px;
        color: #db4140;
        text-transform: uppercase;
      }
      p {
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 2.2;
        color: rgb(119, 124, 124);
        margin-bottom: 0px;
      }
    }
    .data-driven-strategy {
      max-width: 142px;
      width: 100%;
    }
    .human-connections {
      max-width: 115px;
      width: 100%;
    }
    .demand-for-your-brand {
      max-width: 120px;
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    max-width: 700px;
    .three-col {
      flex-wrap: wrap;
      > div {
        width: 50%;
        margin-bottom: 40px;
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    margin: 30px auto;
    h2 {
      font-size: 28px;
    }
    p {
      font-size: 18px;
    }
    .three-col {
      > div {
        width: 100%;
        padding: 0 20px;
        h3 {
          margin-top: 30px;
        }
        p {
          font-size: 16px;
          line-height: 1.6;
        }
      }
    }
  }
`

const ContactSection = styled.section`
  width: 100%;
  max-width: 920px;
  background-color: #2b2520;
  padding: 60px 100px;
  margin: 60px auto;
  h2 {
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 20px;
    color: #db4140;
    text-transform: uppercase;
    text-align: center;
  }
  h3 {
    text-align: center;
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 42px;
    line-height: 1.4;
    margin: 0 auto 40px;
    color: rgb(255, 255, 255);
    max-width: 800px;
  }
  form {
    p {
      label {
        font-family: "Work Sans";
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        color: #fff;
        margin-top: 0;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
      }
      input,
      select,
      textarea {
        font-size: 22px;
        padding: 0 0.65em;
        color: #555;
        height: 60px;
        margin-top: 10px;
        outline: 0;
        &:focus {
          outline: 3px solid #db4140;
        }
      }
      textarea {
        height: 250px;
        padding: 15px;
      }
      input[type="file"] {
        padding: 0;
        font-size: 16px;
        color: #fff;
      }
      &.button {
        width: 100%;
      }
      button[type="submit"] {
        font-family: "Work Sans";
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        color: #fff;
        margin-top: 0;
        margin-bottom: 0px;
        padding: 20px 70px;
        text-align: center;
        background-color: #db4140;
        border: none;
        outline: 0;
        width: 100%;
        &:hover {
          cursor: pointer;
        }
      }
      &.hidden {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
      }
      &.full-row {
        width: 100%;
      }
    }
  }
  @media (max-width: 1000px) {
    max-width: 700px;
  }
  @media (max-width: 767px) {
    max-width: 500px;
    margin: 30px auto;
    padding: 30px 20px;
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 28px;
      line-height: 1.3;
    }
    form {
      p {
        margin-bottom: 20px;
        label {
          font-size: 18px;
        }
        input {
          font-size: 18px;
          height: 40px;
        }
        button[type="submit"] {
          font-size: 18px;
          padding: 10px;
        }
      }
    }
  }
`
const ActionSection = styled.header`
  max-width: 920px;
  width: 100%;
  padding: 80px 70px;
  margin: 20px auto 60px;
  border: 10px solid #db4140;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    line-height: 1.4;
    margin: 0 auto 35px;
    text-align: center;
    max-width: 520px;
  }
  button.red-button {
    font-family: "Work Sans";
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(219, 65, 64);
    display: inline-block;
    padding: 20px 50px;
    margin: 0 auto 50px;
    border: none;
    outline: 0;
    &:hover {
      cursor: pointer;
    }
  }
  button.image-button {
    padding: 0;
    margin: 0 auto;
    border: none;
    outline: 0;
    position: relative;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
    svg {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #fff;
    }
  }
  img {
    margin: 0 auto;
    max-width: 366px;
    width: 100%;
    height: auto;
    border: 3px solid #db4140;
    background-color: #db4140;
  }
  @media (max-width: 1000px) {
    max-width: 700px;
    padding: 45px;
    h3 {
      font-size: 54px;
    }
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    padding: 20px;
    border: 5px solid #db4140;
    margin-bottom: 20px;
    h3 {
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      line-height: 1.4;
    }
    button.red-button {
      padding: 10px;
      font-size: 16px;
      margin-bottom: 25px;
    }
  }
`

export default LandingPage
